import React from 'react'
import SEO from '../components/seo'

// Components
import PageIntro from '../components/Hero/PageIntro'
import { ContentBox, ContentBoxIcon } from '../components/ui/layout/ContentBox'
import { Container, Row, Column } from '../components/ui/layout/Grid'
import theme from '../theme'

// Icons
import whyIcon from '../images/assets/icons/why_icon.svg'
import howIcon from '../images/assets/icons/how_icon.svg'
import faq from '../images/assets/faq.svg'

const Faq = ({ data }) => (
  <>
    <SEO title="FAQ" />
    <PageIntro
      title="Frequently Asked Questions"
      topTitle="Your Burning Questions Answered"
      subtitle="There’s a lot to unpack here. Let us help you with that and provide you with answers. If you have a question which isn’t here you can always contact us."
      image={{
        src: faq,
        alt: 'Frequntly Asked Questions',
        padding: '3.5em'
      }}
    />
    <Container>
      <Row>
        <Column>
          <ContentBox
            bg="#fff"
            border={theme.color.grey[1]}
            color={theme.color.grey[4]}
            bgPattern={true}
          >
            <ContentBoxIcon
              icon={whyIcon}
              outside
            />
            <h2>Why?</h2>
            <p>
              Investabit uses enterprise level data connections and storage solutions to power an extensive catalogue of data and data services, which allows and accounts for data feed gaps, API breakages, and simulated black swan events to see how Investabit handles these rare but critical events.
            </p>
          </ContentBox>
        </Column>
      </Row>
      <Row>
        <Column>
          <ContentBox
            bg="#fff"
            border={theme.color.grey[1]}
            color={theme.color.grey[4]}
            bgPattern={true}
          >
            <ContentBoxIcon
              icon={howIcon}
              outside
            />
            <h2>How?</h2>
            <p>
              Investabit utilizes advanced systems to aggregate data across multiple exchanges, we process and clean the data (for use in Machine Learning) as well as standardize for unification of all exchange data. Through this system we pass along our data through APIs of varying degrees to allow others to interface as they wish for their own needs and systems. Finally we’re hard at work on several other things we’re really proud of such as a backtesting engine (coming soon!) an execution management interface (coming soon!), portfolio management and reporting (coming soon!), and finally a self service build your own signals tool (coming soon!).
            </p>
          </ContentBox>
        </Column>
      </Row>
    </Container>
  </>
)

export default Faq
